<template>
  <v-row>
    <v-col cols="12">
      <v-card class="pa-4 rounded-lg" outlined>
        <v-card-title class="subtitle-1 font-weight-bold">
          결제 확인 {{ orderList.filter((item) => item.status === 3).length }}건
          | 작업 중 {{ orderList.filter((item) => item.status === 4).length }}건
          | 출고 대기
          {{ orderList.filter((item) => item.status === 5).length }}건
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="orderList"
          :items-per-page="orderList.length"
          :sort-by="['confirmedAt']"
          :sort-desc="[true]"
          no-data-text="데이터가 없습니다"
          hide-default-footer
          class="transparent"
          @click:row="openDialog"
        >
          <template v-slot:[`item.displayName`]="{ item }">
            <span
              v-text="
                item.displayName != ''
                  ? item.displayName
                  : item.phoneNumber.substr(item.phoneNumber.length - 4, 4)
              "
            />
          </template>

          <template v-slot:[`item.confirmedAt`]="{ item }">
            <span>
              {{ item.confirmedAt | moment("MM/DD &nbsp;HH:mm") }}
            </span>
          </template>

          <template v-slot:[`item.shipAt`]="{ item }">
            <span>
              {{ item.shipAt || "-" | moment("MM/DD") }}
            </span>
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <v-menu rounded="lg" bottom offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <feather
                    size="24"
                    :type="getStatusData(item.status).icon"
                    :stroke="
                      getStatusData(item.status).color
                        ? getStatusData(item.status).color
                        : 'var(--v-onSurface-base)'
                    "
                  />
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-for="(statusItem, index) in orderStatusItem"
                  :key="index"
                  @click="changeStatusData(item, index)"
                >
                  <v-list-item-title class="body-2">
                    <feather
                      size="18"
                      :type="statusItem.icon"
                      :stroke="statusItem.color"
                      class="mr-2"
                    />
                    {{ statusItem.title }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template v-slot:[`item.productCost`]="{ item }">
            <span>{{ item.productCost.toLocaleString() }}원</span>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import constants from "@/utils/constants";
export default {
  name: "AdminorderList",
  props: {
    orderList: Array,
  },
  data() {
    return {
      orderStatusItem: constants.orderStatusItem,
      headers: [
        {
          text: "성함",
          align: "start",
          sortable: false,
          value: "displayName",
          width: "80px",
        },
        { text: "상태", value: "status", width: "80px" },
        { text: "견적 제목", value: "purpose", width: "" },
        { text: "요청 기한", value: "shipAt", width: "100px" },
        { text: "결제 일시", value: "confirmedAt", width: "120px" },
        { text: "작업 장소", value: "workspace", width: "180px" },
        {
          text: "결제 금액",
          value: "productCost",
          width: "120px",
          align: "right",
        },
      ],
    };
  },
  methods: {
    getStatusData(statusLevel) {
      return this.orderStatusItem[statusLevel];
    },
    changeStatusData(item, index) {
      item.status = index;
      this.$emit("saveOrder", item);
    },
    openDialog(value) {
      this.$emit("openDialog", value);
    },
  },
};
</script>

<style></style>
