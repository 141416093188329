<template>
  <v-dialog
    class="elevation-0"
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    scrollable
    max-width="540px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        medium
        class="mr-2"
        @click="dialog = true"
        v-bind="attrs"
        v-on="on"
      >
        mdi-note-plus
      </v-icon>
    </template>
    <v-card flat class="rounded-xl">
      <v-card-title class="pt-7 px-8">
        <span class="highlight title font-weight-bold onSurface--text">
          기본 정보
        </span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="4" md="3" align-self="center"> 회원 조회 </v-col>
            <v-col cols="8" md="9" class="d-flex">
              <v-text-field
                v-model="searchText"
                class="font-weight-regular"
                flat
                dense
                append-icon=""
                hide-no-data
                hide-details
                solo
                background-color="surface"
                prepend-inner-icon="mdi-magnify"
                label="UID , Email , 이름 , 연락처"
                clearable
              />
              <v-btn
                color="primary"
                class="mx-3"
                @click="userSearch()"
                :loading="searchBtnLoading"
              >
                조회
              </v-btn>
            </v-col>
            <v-col cols="12" v-if="items != ''">
              <v-data-table
                :headers="headers"
                :items="items"
                hide-default-footer
                disable-sort
                class="transparent"
                @click:row="addUser"
              />
              <v-divider />
            </v-col>
            <v-col cols="4" md="3" align-self="center"> 성함 </v-col>
            <v-col cols="8" md="9">
              <span class="onSurface--text" v-if="data.uid">
                {{ data.displayName }}
              </span>
              <v-text-field
                v-else
                v-model="data.displayName"
                class="onSurface--text"
                hide-details
                solo
                dense
                flat
                background-color="surface"
              />
            </v-col>
            <v-col cols="4" md="3" align-self="center"> 연락처 </v-col>
            <v-col cols="8" md="9">
              <span class="onSurface--text" v-if="data.uid">
                {{
                  data.phoneNumber.replace(
                    /^(\d{0,3})(\d{0,4})(\d{0,4})$/g,
                    "$1-$2-$3",
                  )
                }}
              </span>
              <v-text-field
                v-else
                v-model="data.phoneNumber"
                class="onSurface--text"
                hide-details
                solo
                dense
                flat
                background-color="surface"
              />
            </v-col>
            <v-col cols="4" md="3" align-self="center"> 제목 </v-col>
            <v-col cols="8" md="9">
              <v-text-field
                v-model="data.purpose"
                class="onSurface--text"
                hide-details
                solo
                dense
                flat
                background-color="surface"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions class="pa-4">
        <v-spacer />

        <v-btn
          v-if="$vuetify.breakpoint.mdAndUp"
          class="rounded-lg"
          text
          @click="close"
          :ripple="false"
        >
          취소
        </v-btn>
        <v-btn
          color="primary"
          class="rounded-lg font-weight-bold"
          depressed
          :loading="buttonLoading"
          :block="$vuetify.breakpoint.smAndDown"
          @click="addOrder"
        >
          생성
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import constants from "@/utils/constants";

export default {
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  mounted() {
    this.initialize();
  },
  data: () => ({
    dialog: false,
    searchText: "",
    searchBtnLoading: false,
    items: [],
    headers: [
      { text: "이름", value: "displayName", align: "center" },
      { text: "이메일", value: "email", align: "center" },
      { text: "연락처", value: "phoneNumber", align: "center" },
    ],
    buttonLoading: false,
    data: {},
  }),
  methods: {
    // 신규주문서 작성한 사람 식별자 기본 작성
    initialize() {
      this.data = _.cloneDeep(constants.orderFormInterface);
      this.data.status = 3;
      this.data.confirmed = true;
      this.data.confirmedAt = new Date();
      this.searchText = "";
      this.buttonLoading = false;
      this.items = [];
    },
    // 조회버튼 검색
    userSearch() {
      this.searchBtnLoading = true;
      var params = {
        searchText: this.searchText,
      };
      this.$axios
        .get("admin/users", {
          params: params,
        })
        .then(({ data }) => {
          this.items = data.items;
          this.searchBtnLoading = false;
        })
        .catch((e) => {
          this.$toasted.global.error(e.message);
        });
    },
    addUser(value) {
      this.data.uid = value.uid;
      this.data.displayName = value.displayName;
      this.data.email = value.email;
      this.data.phoneNumber = value.phoneNumber;
      this.data.userClass = value.userClass;
      this.data.schoolInfo = value.schoolInfo;
      this.data.companyInfo = value.companyInfo;
    },
    close() {
      this.dialog = false;
      this.initialize();
    },
    async addOrder() {
      if (this.data.displayName == "")
        return this.$toasted.global.error("성함을 입력해 주세요!");
      if (this.data.phoneNumber == "")
        return this.$toasted.global.error("연락처를 입력해 주세요!");
      if (this.data.purpose == "")
        return this.$toasted.global.error("제목을 입력해 주세요!");
      this.buttonLoading = true;
      this.$toasted.global.notice("주문서 생성 중입니다. 잠시만 기다려주세요!");

      let orderID;
      orderID = await this.$axios.put("orders/newOrder/");
      await this.$axios.put("orders/updateOrder/" + orderID.data, {
        form: this.data,
      });
      this.$emit("confirmedList");
      this.buttonLoading = false;
      this.close();
    },
  },
};
</script>
