<template>
  <v-row>
    <v-col cols="12">
      <v-card class="pa-4 rounded-lg" outlined>
        <v-card-title class="subtitle-1 font-weight-bold">
          검색결과 {{ orderList.length }}건
          <v-spacer />
          <v-autocomplete
            v-model="OrderSearchModel"
            :items="OrderSearchItems"
            :search-input.sync="OrderSearch"
            cache-items
            class="ml-4 font-weight-regular"
            flat
            dense
            append-icon=""
            hide-no-data
            hide-details
            solo
            background-color="surface"
            prepend-inner-icon="mdi-magnify"
            label="성함을 입력하세요"
            clearable
          />
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="orderList"
          :items-per-page="orderList.length"
          :sort-by="['createdAt']"
          :sort-desc="[true]"
          no-data-text="데이터가 없습니다"
          hide-default-footer
          class="transparent"
          @click:row="openDialog"
        >
          <template v-slot:[`item.displayName`]="{ item }">
            <span
              v-text="
                item.displayName != ''
                  ? item.displayName
                  : item.phoneNumber.substr(item.phoneNumber.length - 4, 4)
              "
            />
          </template>

          <template v-slot:[`item.createdAt`]="{ item }">
            <span>{{ item.createdAt | moment("MM/DD &nbsp;HH:mm") }} </span>
          </template>
          <template v-slot:[`item.confirmedAt`]="{ item }">
            <span>{{ item.confirmedAt | moment("MM/DD &nbsp;HH:mm") }} </span>
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <v-menu rounded="lg" bottom offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <feather
                    size="24"
                    :type="getStatusData(item.status).icon"
                    :stroke="
                      getStatusData(item.status).color
                        ? getStatusData(item.status).color
                        : 'var(--v-onSurface-base)'
                    "
                  />
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-for="(statusItem, index) in orderStatusItem"
                  :key="index"
                  @click="changeStatusData(item, index)"
                >
                  <v-list-item-title class="body-2">
                    <feather
                      size="18"
                      :type="statusItem.icon"
                      :stroke="statusItem.color"
                      class="mr-2"
                    />
                    {{ statusItem.title }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <v-col cols="12" md="6">
      <v-card class="pa-4 rounded-lg" outlined>
        <v-card-title class="subtitle-1 font-weight-bold">
          신규 주문 <span class="red--text subtitle-2"> /*삭제예정*/ </span>
          <v-spacer />
          <v-btn icon @click="newList" :disabled="newFetchLoading">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
          <div style="width: 150px" class="ml-4">
            <v-autocomplete
              v-model="newOrderSearchModel"
              :items="newOrderSearchItems"
              :search-input.sync="newOrderSearch"
              cache-items
              class="font-weight-regular"
              flat
              dense
              append-icon=""
              hide-no-data
              hide-details
              solo
              background-color="surface"
              prepend-inner-icon="mdi-magnify"
              label="고객 성함"
              clearable
            />
          </div>
        </v-card-title>
        <v-data-table
          :loading="newFetchLoading"
          :headers="newHeaders"
          :items="newOrder"
          :options.sync="newOptions"
          :page.sync="newPage"
          :server-items-length="newTotalCount"
          :items-per-page="newItemsPerPage"
          no-data-text="데이터가 없습니다"
          hide-default-footer
          class="transparent"
          disable-sort
          @page-count="newPageCount = $event"
          @click:row="openDialog"
        >
          <template v-slot:[`item.displayName`]="{ item }">
            <span
              v-text="
                item.displayName != ''
                  ? item.displayName
                  : item.phoneNumber.substr(item.phoneNumber.length - 4, 4)
              "
            />
          </template>

          <template v-slot:[`item.createdAt`]="{ item }">
            <span>{{ item.createdAt | moment("MM/DD &nbsp;HH:mm") }} </span>
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <v-menu rounded="lg" bottom offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <feather
                    size="24"
                    :type="getStatusData(item.status).icon"
                    :stroke="
                      getStatusData(item.status).color
                        ? getStatusData(item.status).color
                        : 'var(--v-onSurface-base)'
                    "
                  />
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-for="(statusItem, index) in orderStatusItem"
                  :key="index"
                  @click="changeStatusData(item, index)"
                >
                  <v-list-item-title class="body-2">
                    <feather
                      size="18"
                      :type="statusItem.icon"
                      :stroke="statusItem.color"
                      class="mr-2"
                    />
                    {{ statusItem.title }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
        <v-pagination
          total-visible="11"
          v-model="newPage"
          :length="newPageCount"
        />
      </v-card>
    </v-col>

    <v-col cols="12" md="6">
      <v-card class="pa-4 rounded-lg" outlined>
        <v-card-title class="subtitle-1 font-weight-bold">
          결제 된 주문 <span class="red--text subtitle-2"> /*삭제예정*/ </span>
          <v-spacer />

          <!-- 수작업으로 신규 주문 추가 -->
          <template v-if="$store.getters['auth/user/GET_CLAIMS'].level < 2">
            <admin-order-add-dialog @confirmedList="confirmedList" />
          </template>

          <v-btn
            class="mr-2"
            icon
            @click="confirmedList"
            :disabled="confirmedFetchLoading"
          >
            <v-icon> mdi-refresh </v-icon>
          </v-btn>

          <v-autocomplete
            v-model="confirmedOrderSearchModel"
            :items="confirmedOrderSearchItems"
            :search-input.sync="confirmedOrderSearch"
            cache-items
            class="ml-4 font-weight-regular"
            flat
            dense
            append-icon=""
            hide-no-data
            hide-details
            solo
            background-color="surface"
            prepend-inner-icon="mdi-magnify"
            label="성함을 입력하세요"
            clearable
          />
        </v-card-title>
        <v-data-table
          :loading="confirmedFetchLoading"
          :headers="confirmedHeaders"
          :items="confirmedOrder"
          :options.sync="confirmedOptions"
          :page.sync="confirmedPage"
          :items-per-page="confirmedItemsPerPage"
          :server-items-length="confirmedTotalCount"
          @page-count="confirmedPageCount = $event"
          hide-default-footer
          class="transparent"
          no-data-text="데이터가 없습니다"
          disable-sort
          @click:row="openDialog"
        >
          <template v-slot:[`item.displayName`]="{ item }">
            <span>
              {{
                item.displayName != ""
                  ? item.displayName
                  : item.phoneNumber.substr(item.phoneNumber.length - 4, 4)
              }}
            </span>
          </template>

          <template v-slot:[`item.confirmedAt`]="{ item }">
            <span>
              {{ item.confirmedAt | moment("MM/DD &nbsp;HH:mm") }}
            </span>
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <v-menu rounded="lg" bottom offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <feather
                    size="24"
                    :type="getStatusData(item.status).icon"
                    :stroke="
                      getStatusData(item.status).color
                        ? getStatusData(item.status).color
                        : 'var(--v-onSurface-base)'
                    "
                  />
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-for="(statusItem, index) in orderStatusItem"
                  :key="index"
                  @click="changeStatusData(item, index)"
                >
                  <v-list-item-title class="body-2">
                    <feather
                      size="18"
                      :type="statusItem.icon"
                      :stroke="statusItem.color"
                      class="mr-2"
                    />
                    {{ statusItem.title }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>

        <v-pagination
          total-visible="11"
          v-model="confirmedPage"
          :length="confirmedPageCount"
        />
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import AdminOrderAddDialog from "@/components/Dialog/Admin/Order/AdminOrderAddDialog.vue";
import _ from "lodash";
import constants from "@/utils/constants";
export default {
  name: "AdminOrderSearch",
  components: {
    AdminOrderAddDialog,
  },
  data() {
    return {
      orderStatusItem: constants.orderStatusItem,

      OrderSearchModel: "",
      OrderSearchItems: [],
      OrderSearch: null,

      headers: [
        {
          text: "성함",
          align: "start",
          sortable: false,
          value: "displayName",
          width: "80px",
        },
        { text: "상태", value: "status", width: "80px", align: "center" },
        { text: "견적 제목", value: "purpose", width: "" },
        { text: "결제 일시", value: "confirmedAt", width: "120px" },
        { text: "작업 장소", value: "workspace", width: "180px" },
        { text: "접수 일시", value: "createdAt", width: "120px" },
      ],

      orderList: [],

      newHeaders: [
        {
          text: "성함",
          align: "start",
          sortable: false,
          value: "displayName",
          width: "80px",
        },
        { text: "상태", value: "status", width: "68px", align: "center" },
        { text: "견적 제목", value: "purpose", width: "" },
        { text: "접수 일시", value: "createdAt", width: "130px" },
      ],
      confirmedHeaders: [
        {
          text: "성함",
          align: "start",
          sortable: false,
          value: "displayName",
          width: "80px",
        },
        { text: "상태", value: "status", width: "68px", align: "center" },
        { text: "견적 제목", value: "purpose", width: "" },
        { text: "결제 일시", value: "confirmedAt", width: "130px" },
      ],
      confirmedFetchLoading: false,
      newFetchLoading: false,
      newPage: 1,
      newPageCount: 0,
      newItemsPerPage: 20,
      newOptions: {
        sortBy: ["createdAt"],
        sortDesc: [true],
      },
      newTotalCount: 0,

      confirmedPage: 1,
      confirmedPageCount: 0,
      confirmedItemsPerPage: 20,
      confirmedOptions: {
        sortBy: ["confirmedAt"],
        sortDesc: [true],
      },
      confirmedTotalCount: 0,

      newOrder: [],
      confirmedOrder: [],

      newOrderSearchModel: "",
      newOrderSearchItems: [],
      newOrderSearch: null,
      loadingNewOrderSearch: false,

      confirmedOrderSearchModel: "",
      confirmedOrderSearchItems: [],
      confirmedOrderSearch: null,
      loadingConfirmedOrderSearch: false,

      autoReloadFunction: null,
    };
  },
  watch: {
    OrderSearch(val) {
      console.log("search");
      val && val !== this.OrderSearchModel && this.searchOrderNames(val);
    },
    OrderSearchModel(n, o) {
      console.log("searchmodel");
      if (n !== o) this.getOrder();
    },
    newOptions: {
      handler() {
        this.newList();
      },
      deep: true,
    },
    confirmedOptions: {
      handler() {
        this.confirmedList();
      },
      deep: true,
    },
    newOrderSearch(val) {
      val && val !== this.newOrderSearchModel && this.searchNewOrderNames(val);
    },
    newOrderSearchModel(n, o) {
      if (n !== o) this.newList();
    },
    confirmedOrderSearch(val) {
      val &&
        val !== this.confirmedOrderSearchModel &&
        this.searchConfirmedOrderNames(val);
    },
    confirmedOrderSearchModel(n, o) {
      if (n !== o) this.confirmedList();
    },
  },
  mounted() {
    this.reloadAll();
  },
  methods: {
    searchOrderNames: _.debounce(
      // eslint-disable-next-line no-unused-vars
      function (val) {
        this.$axios
          .get("admin/orderSearchByName", {
            params: { search: this.OrderSearch },
          })
          .then(({ data }) => {
            this.OrderSearchItems = data;
          })
          .catch((e) => {
            this.$toasted.global.error(e.message);
            clearInterval(this.autoReloadFunction);
          });
      },
      500,
    ),
    getOrder() {
      this.$axios
        .get("admin/getOrder", {
          params: {
            displayName: this.OrderSearchModel,
          },
        })
        .then(({ data }) => {
          this.orderList = data;
        })
        .catch((e) => {
          this.$toasted.global.error(e.message);
        });
    },
    searchNewOrderNames: _.debounce(
      // eslint-disable-next-line no-unused-vars
      function (val) {
        this.loadingNewOrderSearch = true;
        this.$axios
          .get("admin/orderSearchByName", {
            params: { search: this.newOrderSearch, confirmed: false },
          })
          .then(({ data }) => {
            this.newOrderSearchItems = data;
          })
          .catch((e) => {
            this.$toasted.global.error(e.message);
            clearInterval(this.autoReloadFunction);
          })
          .finally(() => {
            this.loadingNewOrderSearch = false;
          });
      },
      500,
    ),
    newList() {
      this.newFetchLoading = true;
      this.$axios
        .get("admin/newOrders", {
          params: {
            offset:
              this.newPage > 0 ? (this.newPage - 1) * this.newItemsPerPage : 0,
            limit: this.newItemsPerPage,
            order: this.newOptions.sortBy[0],
            sort: this.newOptions.sortDesc[0] ? "desc" : "asc",
            search: this.newOrderSearchModel,
          },
        })
        .then(({ data }) => {
          this.newTotalCount = data.totalCount;
          this.newOrder = data.items;
        })
        .catch((e) => {
          this.$toasted.global.error(e.message);
        })
        .finally(() => {
          this.newFetchLoading = false;
        });
    },
    searchConfirmedOrderNames: _.debounce(
      // eslint-disable-next-line no-unused-vars
      function (val) {
        this.loadingConfirmedOrderSearch = true;
        this.$axios
          .get("admin/orderSearchByName", {
            params: { search: this.confirmedOrderSearch, confirmed: true },
          })
          .then(({ data }) => {
            this.confirmedOrderSearchItems = data;
          })
          .catch((e) => {
            this.$toasted.global.error(e.message);
          })
          .finally(() => {
            this.loadingConfirmedOrderSearch = false;
          });
      },
      500,
    ),
    confirmedList() {
      this.confirmedFetchLoading = true;
      this.$axios
        .get("admin/confirmedOrders", {
          params: {
            offset:
              this.confirmedPage > 0
                ? (this.confirmedPage - 1) * this.confirmedItemsPerPage
                : 0,
            limit: this.confirmedItemsPerPage,
            order: this.confirmedOptions.sortBy[0],
            sort: this.confirmedOptions.sortDesc[0] ? "desc" : "asc",
            search: this.confirmedOrderSearchModel,
          },
        })
        .then(({ data }) => {
          this.confirmedTotalCount = data.totalCount;
          this.confirmedOrder = data.items;
        })
        .catch((e) => {
          this.$toasted.global.error(e.message);
        })
        .finally(() => {
          this.confirmedFetchLoading = false;
        });
    },
    getStatusData(statusLevel) {
      return this.orderStatusItem[statusLevel];
    },
    changeStatusData(item, index) {
      item.status = index;
      this.$emit("saveOrder", item);
    },
    reloadAll() {
      this.newList();
      this.confirmedList();
    },
    // autoReloadList() {
    //   this.autoReloadFunction = setInterval(() => {
    //     if (this.$store.getters["auth/user/GET_USER"]) {
    //       this.reloadAll();
    //     }
    //   }, 300000);
    // },
    openDialog(value) {
      this.$emit("openDialog", value);
    },
  },
};
</script>

<style></style>
