<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card class="transparent" flat>
          <v-card-title class="title font-weight-bold">
            <span class=""> 주문 관리 </span>
          </v-card-title>
          <v-card-subtitle>
            {{ subtitle[$router.currentRoute.query.type] }}
          </v-card-subtitle>
        </v-card>
      </v-col>
      <admin-order-search
        v-if="type == 'SEARCH'"
        @openDialog="openDialog"
        @saveOrder="saveOrder"
      />
      <admin-new-order
        v-else-if="type == 'NEW'"
        :orderList="newOrder"
        @openDialog="openDialog"
        @saveOrder="saveOrder"
      />
      <admin-confirm-order
        v-else-if="type == 'CONFIRM'"
        :orderList="comfirmedOrder"
        @openDialog="openDialog"
        @saveOrder="saveOrder"
      />
    </v-row>
    <admin-order-dialog
      :dialog="dialog"
      :data="dialogData"
      @saveOrder="saveOrder"
      @closeDialog="closeDialog"
    />
  </v-container>
</template>

<script>
import _ from "lodash";
import firestorageUtils from "@/utils/firestorage";
import setMeta from "@/utils/setMeta";
import constants from "@/utils/constants";
import AdminOrderDialog from "../../components/Dialog/Admin/Order/AdminOrderDialog.vue";
import AdminOrderSearch from "./Components/AdminOrderSearch.vue";
import AdminNewOrder from "./Components/AdminNewOrder.vue";
import AdminConfirmOrder from "./Components/AdminConfirmOrder.vue";

export default {
  components: {
    AdminOrderDialog,
    AdminOrderSearch,
    AdminNewOrder,
    AdminConfirmOrder,
  },
  data() {
    return {
      subtitle: {
        SEARCH: "통합 검색 페이지입니다.",
        NEW: "신규 및 상담 중 주문건입니다.",
        CONFIRM: "결제 확인된 주문건입니다.",
      },
      type: this.$router.currentRoute.query.type || "NEW",

      unsubscribeNew: null,
      newOrder: [],
      unsubscribeConfirm: null,
      comfirmedOrder: [],

      dialog: false,
      dialogData: {},
    };
  },
  beforeCreate() {
    setMeta({
      title: "주문 관리",
      description: "메이드올 관리자페이지입니다.",
      image:
        "https://firebasestorage.googleapis.com/v0/b/madeall.appspot.com/o/Assets%2FOG%2Flogin.png?alt=media&token=171e59e8-a13a-43eb-83f1-5f2b0a5629a6",
    });
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.type = this.$router.currentRoute.query.type;
      },
    },
  },
  mounted() {
    this.newList();
    this.confirmedList();
  },
  methods: {
    newList() {
      this.newOrder = [];
      if (this.unsubscribeNew) {
        this.unsubscribeNew();
      }
      const db = this.$firebase.firestore();
      this.unsubscribeNew = db
        .collection("orders")
        .where("status", "<=", 1)
        .limit(500)
        .onSnapshot((v) => {
          v.docChanges().forEach((change) => {
            const item = change.doc.data();
            if (item.createdAt) item.createdAt = item.createdAt.toDate();
            if (item.updatedAt) item.updatedAt = item.updatedAt.toDate();
            if (item.confirmedAt) item.confirmedAt = item.confirmedAt.toDate();
            if (item.finishedAt) item.finishedAt = item.finishedAt.toDate();
            console.log("newOrderSnapshot");
            if (change.type === "added") {
              this.newOrder.unshift(item);
            }
            if (change.type === "modified") {
              const index = this.newOrder.findIndex(
                (order) => order.id === item.id,
              );
              if (index !== -1) {
                this.$set(this.newOrder, index, item);
              }
            }
            if (change.type === "removed") {
              const index = this.newOrder.findIndex(
                (order) => order.id === item.id,
              );
              if (index !== -1) {
                this.newOrder.splice(index, 1);
              }
            }
          });
        });
    },
    confirmedList() {
      this.comfirmedOrder = [];
      if (this.unsubscribeConfirm) {
        this.unsubscribeConfirm();
      }
      const db = this.$firebase.firestore();
      this.unsubscribeConfirm = db
        .collection("orders")
        .where("finishedAt", "==", null)
        .where("confirmed", "==", true)
        .limit(120)
        .onSnapshot((v) => {
          v.docChanges().forEach((change) => {
            const item = change.doc.data();
            if (item.createdAt) item.createdAt = item.createdAt.toDate();
            if (item.updatedAt) item.updatedAt = item.updatedAt.toDate();
            if (item.confirmedAt) item.confirmedAt = item.confirmedAt.toDate();
            if (item.finishedAt) item.finishedAt = item.finishedAt.toDate();
            console.log("confirmedOrderSnapshot");
            if (change.type === "added") {
              this.comfirmedOrder.unshift(item);
            }
            if (change.type === "modified") {
              const index = this.comfirmedOrder.findIndex(
                (order) => order.id === item.id,
              );
              if (index !== -1) {
                this.$set(this.comfirmedOrder, index, item);
              }
            }
            if (change.type === "removed") {
              const index = this.comfirmedOrder.findIndex(
                (order) => order.id === item.id,
              );
              if (index !== -1) {
                this.comfirmedOrder.splice(index, 1);
              }
            }
          });
        });
    },
    async saveOrder(order) {
      // 주문서 결제 확인 점검
      if (order.status < 3) {
        if (order.confirmed) {
          order.confirmed = false;
          order.confirmedAt = null;
        }
      } else {
        if (!order.confirmed) {
          order.confirmed = true;
          order.confirmedAt = new Date();
        }
      }
      try {
        await Promise.all(
          order.file.map(async (v) => {
            if (v.previewImg.startsWith("data:image")) {
              const blob = await (await fetch(v.previewImg)).blob();
              const result = await firestorageUtils.uploadFile({
                file: blob,
                path: v.filePath.replace(/\.stl$/, "_Img.png"),
              });
              v.previewImg = result.downloadURL;
            }
          }),
        );
        await this.$axios.put("admin/updateOrder/" + order.id, {
          admin: this.$store.getters["auth/user/GET_USER"].displayName,
          item: order,
        });
        this.closeDialog();
      } catch (e) {
        this.$toasted.global.error(e.message);
      }
    },
    openDialog(value) {
      this.dialogData = _.merge(
        _.cloneDeep(constants.orderFormInterface),
        value,
      );
      this.dialog = true;
    },
    closeDialog() {
      this.dialogData = _.cloneDeep(constants.orderFormInterface);
      this.dialog = false;
    },
  },
};
</script>
